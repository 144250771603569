<template>
  <div class="wrapper">
    <div class="background-logo"></div>
    <!-- Hintergrund-Logo -->

    <div class="form-container">
      <h2>Log in</h2>
      <form @submit.prevent="login">
        <input type="text" v-model="username" placeholder="Username" required />
        <input
          type="password"
          v-model="password"
          placeholder="Password"
          required
        />
        <button type="submit" :disabled="isLoading">
          <span v-if="isLoading">Logging in...</span>
          <span v-else>Log in</span>
        </button>
        <p class="error" v-if="loginError">Wrong username or password.</p>
      </form>
    </div>

    <p>This site is currently under construction</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      loginError: false,
      isLoading: false,
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      this.loginError = false;

      setTimeout(() => {
        if (this.username === "admin123" && this.password === "passworte3q3e") {
          alert("Erfolgreich angemeldet als " + this.username);
          this.loginError = false;
        } else {
          this.loginError = true;
        }
        this.isLoading = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Verhindert Scrollen */
  touch-action: none;
}

body {
  overscroll-behavior: none; /* Verhindert Bounce-Scrolling */
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Nutzt die volle Höhe von html und body */
  width: 100vw; /* Nutzt die volle Breite */
  background-color: black; /* Nur das schwarze Wrapper zählt */
}

.background-logo {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/W-Logo.png") no-repeat center;
  background-size: 1000px; /* Größe des Logos anpassen */
  opacity: 0.08;
  z-index: 0; /* Logo hinter dem Formular */
  animation: rotate 10s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-container {
  background-color: rgb(13, 12, 12);
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  border: 1px solid rgb(102, 102, 102);
  z-index: 1; /* Formular vor dem Logo */
  position: relative; /* Position relativ, damit Z-Index wirkt */
  opacity: 0.75;
  overflow: hidden;
}

input[type="text"],
input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0 15px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

button {
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

button[disabled] {
  background-color: #999;
  cursor: not-allowed;
}

button:hover:not([disabled]) {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>
