<template>
  <div id="app">
    <img alt="Logo" src="./assets/W-Logo.png" />
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "./components/LoginForm.vue";

export default {
  name: "App",
  components: {
    LoginForm,
  },
};
</script>

<style>
html,
body {
  margin: 0;
}
img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0; /* Margin auf 0 setzen */
  border: 0; /* Border auf 0 setzen */
  padding: 0; /* Padding auf 0 setzen */
}
</style>
